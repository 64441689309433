import styles from '@styles/components/Footer.module.scss';

import dynamic from 'next/dynamic';

const IconAppStore = dynamic(() => import('@public/svg/appStore.svg'), {
  ssr: false,
});
const IconGGPlay = dynamic(() => import('@public/svg/ggPlay.svg'), {
  ssr: false,
});

type Props = {
  wrapperStyle?: string,
};

export default function DownloadComponent(props: Props) {
  const { wrapperStyle } = props;
  return (
    <div className={`${wrapperStyle}`}>
      <div className="text-15 font-textBold font-bold text-white">
        TẢI ỨNG DỤNG TRÊN ĐIỆN THOẠI
      </div>
      <div className="flex flex-row items-center mt-8px">
        <div
          onClick={() =>
            window.open(
              'https://play.google.com/store/apps/details?id=com.app.realtech247.prod',
            )
          }
          className={`${styles.buttonDownLoad} mr-16px`}>
          <IconGGPlay className="w-24px h-24px mr-4px" />
          <div className="boldBodyText">Google Play</div>
        </div>
        <div
          className={styles.buttonDownLoad}
          onClick={() =>
            window.open(
              'https://apps.apple.com/vn/app/realtech247-5-0/id1635006393?l=vi',
            )
          }>
          <IconAppStore className="w-24px h-24px mr-4px" />
          <div className="boldBodyText">App Store</div>
        </div>
      </div>
    </div>
  );
}
